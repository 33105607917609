import { checkCognitoAuthAB } from '@/resources/cognitoResource.js';
import { Buffer } from 'buffer';
import { getCurrentUser, getPreferredMFA } from './amplifyService.js';

function isEmailinWhiteList(email) {
  const whitelist = [
    'sulamita.schier@contaazul.com',
    'clarissa.tomaz@contaazul.com',
    'lygia.wielke@contaazul.com',
    'finholding.patrimonial@contaazul.com',
    'financeiro.swipe@contaazul.com',
    'clarissa.thomaz@contaazul.com',
    'camile.kreuch@contaazul.com',
  ];

  if (whitelist.includes(email.toLowerCase())) return true;

  return false;
}

function isContaAzulLogin(email) {
  return email.toLowerCase().endsWith('@contaazul.com');
}

function isSreContaAzulLogin(email) {
  return email.toLowerCase() === 'sre@contaazul.com';
}

export async function hasCognitoAuth(email) {
  if (isEmailinWhiteList(email)) return false;

  if (isContaAzulLogin(email)) return true;

  const hash = Buffer.from(email).toString(`base64`);

  const { data: hasCognitoAuthAB } = await checkCognitoAuthAB(hash);

  return hasCognitoAuthAB;
}

export async function hasRequiredTotpConfiguration(attributes) {
  const MFA_ACTIVE_STATUS = 'ACTIVE';

  const { email, 'custom:force_mfa_setup': forceMfa } = attributes;

  const MFA_NOT_CONFIGURED = 'NOMFA';

  if (isSreContaAzulLogin(email)) {
    return {
      hasRequiredTotpConfiguration: false,
    };
  }

  if (forceMfa !== MFA_ACTIVE_STATUS) {
    return {
      hasRequiredTotpConfiguration: false,
    };
  }

  const user = await getCurrentUser();
  const preference = await getPreferredMFA(user);

  return {
    hasRequiredTotpConfiguration: preference === MFA_NOT_CONFIGURED,
  };
}
