<template>
  <div>
    <ds-row>
      <ds-col>
        <ds-heading size="md" text="Recuperar senha" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-form
          :on-submit="onSubmit"
          :on-submit-success="onSubmitSuccess"
          :on-submit-error="onSubmitError"
          :error-message="errorMessage">
          <ds-row>
            <ds-col>
              <ds-p>Para recuperar sua senha, digite abaixo o e-mail que você usa para acessar a Conta Azul</ds-p>
            </ds-col>
          </ds-row>
          <ds-row>
            <ds-col>
              <ds-field label="E-mail cadastrado">
                <ds-email-input v-model="registeredEmail" required />
              </ds-field>
            </ds-col>
          </ds-row>
          <ds-row>
            <ds-col size="6">
              <ds-button full-width @click="cancel"> Cancelar </ds-button>
            </ds-col>
            <ds-col size="6">
              <ds-submit-button full-width> Recuperar Senha </ds-submit-button>
            </ds-col>
          </ds-row>
          <ds-row>
            <ds-col>
              <div class="ds-u-margin-top">
                <ds-p>
                  Se você não utiliza mais o e-mail cadastrado na Conta Azul, fale com o nosso time pelo telefone
                  <ds-link
                    ref="linkSupportPhone"
                    href="tel:08006000920"
                    @click="trackOnClick('clickedLinkSupportEmail')">
                    0800 600 0920
                  </ds-link>
                  se ainda não for cliente, ou através do WhatsApp
                  <ds-external-link href="https://api.whatsapp.com/send?phone=554735121777" with-icon>
                    (47) 3512-1777
                  </ds-external-link>
                  caso já seja cliente.
                </ds-p>
              </div>
            </ds-col>
          </ds-row>
        </ds-form>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import getRequestErrorMessage from '@/services/requestErrorMessage';
import track from '@/services/trackService';
import TRACKS_EVENTS from '@/services/trackConstants';
import { requestPasswordRecovery } from '@/resources/passwordRecovery';
import { hasCognitoAuth } from '@/services/cognitoService';
import { forgotPassword } from '@/services/amplifyService';
import {
  triedToRecoveryPasswordTrack,
  errorRecoveryPasswordTrack,
  successRecoveryPasswordTrack,
} from '@/services/authenticationTrack/authenticationTrackService';
import { REQUEST_RECOVER_PASSWORD_ERROR } from './ForgotPasswordRequestMessages';
import ForgotPasswordStore from './ForgotPasswordStore';

export default {
  name: 'ForgotPasswordForm',
  data() {
    return {
      registeredEmail: ForgotPasswordStore.email,
      errorMessage: null,
      isCognito: false,
    };
  },
  methods: {
    async onSubmit() {
      track({
        ...TRACKS_EVENTS.passwordRecoveryFormSubmitted,
        properties: {
          email: this.registeredEmail,
        },
      });
      this.errorMessage = null;
      this.isCognito = await hasCognitoAuth(this.registeredEmail);
      triedToRecoveryPasswordTrack({
        email: this.registeredEmail,
        isCognito: this.isCognito,
      });
      return this.isCognito
        ? forgotPassword(this.registeredEmail)
        : requestPasswordRecovery({
            email: this.registeredEmail,
          });
    },
    onSubmitSuccess(data) {
      ForgotPasswordStore.email = this.registeredEmail;
      successRecoveryPasswordTrack({
        email: this.registeredEmail,
        isCognito: this.isCognito,
        properties: data,
      });
      if (this.isCognito) {
        ForgotPasswordStore.cognitoRecoveryCodeData = data;
        ForgotPasswordStore.cognitoCodeSent = true;
      } else {
        ForgotPasswordStore.emailAlreadySent = true;
      }
    },
    onSubmitError(error) {
      errorRecoveryPasswordTrack({
        email: this.registeredEmail,
        isCognito: this.isCognito,
        properties: {
          error,
        },
      });
      this.errorMessage = getRequestErrorMessage(error, REQUEST_RECOVER_PASSWORD_ERROR.GENERIC_FAIL);
    },
    cancel() {
      track(TRACKS_EVENTS.clickedCancelButton);
      this.$router.push('/');
    },
    trackOnClick(eventName) {
      track(TRACKS_EVENTS[eventName]);
    },
  },
};
</script>
