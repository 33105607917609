import Login from '@/components/login/Login';
import ForgotPassword from '@/components/forgotPassword/ForgotPassword';
import MfaSignupVerification from '@/components/mfa/MfaSignupVerification';
import MfaSigninVerification from '@/components/mfa/MfaSigninVerification';
import MfaVerificationChangePhone from '@/components/mfa/MfaVerificationChangePhone';
import MfaTotpConfiguration from '@/components/mfa/MfaTotpConfiguration';
import PasswordRequired from '@/components/passwordRequired/PasswordRequired';
import RecoverSupportID from '@/components/recoverSupportID/RecoverSupportID';
import LoginPage from './LoginPage';

export default [
  {
    path: '/',
    component: LoginPage,
    children: [
      {
        path: '',
        name: 'Login',
        component: Login,
      },
      {
        path: '/recuperar-senha',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },
      {
        path: '/recuperar-id-suporte',
        name: 'RecoverSupportID',
        component: RecoverSupportID,
      },
      {
        path: '/verificacao-duas-etapas-cadastro',
        name: 'MFASignup',
        component: MfaSignupVerification,
      },
      {
        path: '/verificacao-duas-etapas-login',
        name: 'MFASignin',
        component: MfaSigninVerification,
      },
      {
        path: '/redefinir-telefone',
        name: 'MfAChangePhonePage',
        component: MfaVerificationChangePhone,
      },
      {
        path: '/configuracao-totp',
        name: 'MfaTotpConfiguration',
        component: MfaTotpConfiguration,
      },
      {
        path: '/cadastrar-senha',
        name: 'PasswordRequired',
        component: PasswordRequired,
      },
    ],
  },
];
