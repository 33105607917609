import { to } from 'await-to-js';
import { errorLoginTrack } from './authenticationTrack/authenticationTrackService';
import { signin, signOut } from './amplifyService.js';
import { getError } from './errorsService.js';
import { bootstrapAuthToken } from './bootstrapAuthToken.js';
import { migrateSignin } from './migrateSigninService.js';
import { hasRequiredTotpConfiguration } from './cognitoService.js';

export async function signInAndRedirect(username, password, app) {
  let err = null;
  let data = null;

  await to(signOut());
  const [errorMigrate] = await to(migrateSignin(username, password));

  if (errorMigrate) {
    errorLoginTrack({
      email: username,
      isCognito: true,
      properties: {
        step: 'migrateSignin',
        error: errorMigrate.response?.data,
      },
    });
    return Promise.reject(getError(errorMigrate.response?.data?.code));
  }

  [err, data] = await to(signin(username, password));

  if (err) {
    errorLoginTrack({
      email: username,
      isCognito: true,
      properties: {
        step: 'cognitoSignin',
        error: err,
      },
    });
    return Promise.reject(getError(err?.code));
  }

  const resolveChallenges = ['SMS_MFA', 'SOFTWARE_TOKEN_MFA', 'NEW_PASSWORD_REQUIRED'];
  if (resolveChallenges.includes(data.challengeName)) {
    return Promise.resolve(data);
  }

  const [, dataRequiredTotp] = await to(hasRequiredTotpConfiguration(data.attributes));

  if (dataRequiredTotp?.hasRequiredTotpConfiguration && !app) {
    return Promise.resolve(dataRequiredTotp);
  }

  [err, data] = await to(bootstrapAuthToken(data, app));

  if (err) {
    errorLoginTrack({
      email: username,
      isCognito: true,
      properties: {
        step: err.step,
        error: err.message,
      },
    });
    return Promise.reject(getError(err?.message?.code));
  }

  return Promise.resolve(data);
}
